<template>
  <component :is="meetingroomData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="meetingroomData === undefined"
    >
      <h4 class="alert-heading">
        Errore dati Sala Riunioni
      </h4>
      <div class="alert-body">
        Nessuna Sala Riunioni trovata con questo ID. Controlla
        <b-link
          class="alert-link"
          :to="{ name: 'apps-meetingrooms-list' }"
        >
          Lista Sale Riunioni
        </b-link>
        per altre Sale Riunioni.
      </div>
    </b-alert>

    <template
      v-if="meetingroomData"
      pills
    >
      <!-- Tab: Information -->
      <b-row>
        <b-col>
          <meetingrooms-edit-tab-information
            :meetingroom-data="meetingroomData"
            :headquarters="headquarters"
          />
        </b-col>
      </b-row>
    </template>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BCol, BRow,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import headquartersStoreModule from '@/views/apps/headquarters/headquartersStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import MeetingroomsEditTabInformation from './MeetingroomsEditTabInformation.vue'
import meetingroomsStoreModule from '../meetingroomsStoreModule'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BCol,
    BRow,

    MeetingroomsEditTabInformation,
  },
  setup() {
    const toast = useToast()
    const meetingroomData = ref(null)

    const MEETINGROOMS_APP_STORE_MODULE_NAME = 'app-meetingrooms'
    const HEADQUARTERS_APP_STORE_MODULE_NAME = 'app-headquarters'

    // Register module
    if (!store.hasModule(MEETINGROOMS_APP_STORE_MODULE_NAME)) {
      store.registerModule(MEETINGROOMS_APP_STORE_MODULE_NAME, meetingroomsStoreModule)
    }
    if (!store.hasModule(HEADQUARTERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(HEADQUARTERS_APP_STORE_MODULE_NAME, headquartersStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEETINGROOMS_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(MEETINGROOMS_APP_STORE_MODULE_NAME)
      }
      if (store.hasModule(HEADQUARTERS_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(HEADQUARTERS_APP_STORE_MODULE_NAME)
      }
    })

    store
      .dispatch('app-meetingrooms/fetchMeetingroom', { id: router.currentRoute.params.id })
      .then(response => {
        meetingroomData.value = response.data
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    const headquarters = ref(null)
    const _headquarters = [
      {
        value: null,
        text: 'Seleziona una Sede',
      },
    ]
    store
      .dispatch('app-headquarters/fetchHeadquarters')
      .then(response => {
        response.data[0].forEach((headquarter, i) => {
          const _headquarter = {
            value: headquarter.id,
            text: headquarter.name,
          }
          _headquarters.push(_headquarter)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
    headquarters.value = _headquarters

    return {
      meetingroomData,
      headquarters,
    }
  },
}
</script>

<style></style>
